<template>
  <div class="reward-apply" :class="{skeleton : !state.loaded }">
    <template v-if="$store.state.account.loggedIn">
      <div class="tabs" v-if="computedGetTabs && computedGetTabs.length" :id="`${component.name}Tabs`">
        <div class="container">
          <div class="wrapper">
            <div class="tab" :class="{'active': state.tab === idx, [t.name]: t.name}" v-for="(t,idx) in computedGetTabs" :key="idx" @click="moveScrollByTab(idx)">
              <span>{{ t.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="content">
          <div class="steps">
            <ul class="tight">
              <template v-for="(s, idx) in state.steps">
                <li :class="{ pointer: state.loaded, 'active color-point': state.step === s.step }" :key="idx" v-if="s">
                  <a @click="changeStep(s.step, true)">
                    <span class="step font-xs" v-if="state.form.new.simulationFlag !== 'Y'">STEP {{ idx + 1 }}</span>
                    <span v-else-if="s.step" class="step font-xs">STEP {{ idx + 1 }}</span>
                    <div class="title font-sm">
                      <span>{{ s.title }}</span>
                    </div>
                    <span class="ico">
                    <span class="img" :style="{'background-image':`url(/assets/ico/${s.ico})`}"></span>
                  </span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="core">
            <div class="header" v-if="computedSelectedStep">
              <div class="desc font-base">
                <span>STEP {{ computedSelectedStep.step }} {{ computedSelectedStep.desc }}</span>
              </div>
            </div>
            <div class="wrapper" ref="wrapRef">
              <NewRewardApplyStep1 :form="state.form" :files="state.files" :validators="state.validators" :save="save" :warn="warn" v-show="state.step === 1" v-if="state.openedSteps.indexOf(1) >= 0" :keys="state.keys"/>
              <RewardApplyStep2 :form="state.form" :files="state.files" :validators="state.validators" :save="save" :warn="warn" v-show="state.step === 2" v-if="state.openedSteps.indexOf(2) >= 0"/>
              <NewRewardApplyStep3 :form="state.form" :files="state.files" :validators="state.validators" :save="save" :warn="warn" v-show="state.step === 3" v-if="state.openedSteps.indexOf(3) >= 0" :keys="state.keys"
                                   :tabs="computedGetTabs" :isAdminPage="computedIsAdminPage"
                                   :trigger.sync="state.trigger"/>
              <NewRewardApplyStep4 :form="state.form" :files="state.files" :validators="state.validators" :save="save" :warn="warn" v-show="state.step === 4" v-if="state.openedSteps.indexOf(4) >= 0"/>
              <NewRewardApplyStep5 :form="state.form" :files="state.files" :validators="state.validators" :save="save" :warn="warn" v-show="state.step === 5" v-if="state.openedSteps.indexOf(5) >= 0" :loaded="state.loaded"/>
              <RewardApplyStep6 :form="state.form" :files="state.files" :validators="state.validators" :save="save" :warn="warn" v-show="state.step === 6" v-if="state.openedSteps.indexOf(6) >= 0"/>
              <div class="actions" :class="{'step1' : state.step === 1}">
                <div class="second" v-if="state.step !== 1">
                  <a class="save btn btn-light btn-block" @click="changeStep(getStep(-1), true)">이전 단계</a>
                </div>
                <div class="second">
                  <button class="prev btn btn-light btn-block" @click="preview()">미리보기</button>
                </div>
                <div class="processing" v-if="computedIsAdminPage">
                  <button class="next btn btn-point btn-block" @click="save()">변경 사항 저장</button>
                </div>
                <template v-else>
                  <div class="second">
                    <button class="save btn btn-light btn-block" @click="save()">임시 저장</button>
                  </div>
                  <div class="main">
                    <button class="next btn btn-point btn-block" @click="submit" v-if="!getStep(1)">프로젝트 신청</button>
                    <button class="next btn btn-point btn-block" @click="saveAndMoveNext()" v-else>저장 후 다음 단계</button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <Loading :opaque="true" v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, set, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import RewardApplyStep1 from "./RewardApplyStep1";
import RewardApplyStep2 from "./RewardApplyStep2";
import RewardApplyStep3 from "./RewardApplyStep3";
import RewardApplyStep4 from "./RewardApplyStep4";
import RewardApplyStep5 from "./RewardApplyStep5";
import RewardApplyStep6 from "./RewardApplyStep6";
import store from "@/scripts/store";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import NewRewardApplyStep1 from "@/pages/apply/NewRewardApplyStep1.vue";
import NewRewardApplyStep2 from "@/pages/apply/NewRewardApplyStep2.vue";
import NewRewardApplyStep4 from "@/pages/apply/NewRewardApplyStep4.vue";
import NewRewardApplyStep3 from "@/pages/apply/NewRewardApplyStep3.vue";
import NewRewardApplyStep5 from "@/pages/apply/NewRewardApplyStep5.vue";
import rewardStoryTabs from "@/scripts/rewardStoryTabs";
import Loading from "@/components/Loading.vue";

function Component(initialize) {
  this.name = "pageApplyRewardMaker";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Loading,
    NewRewardApplyStep5,
    NewRewardApplyStep3,
    NewRewardApplyStep4,
    NewRewardApplyStep2,
    NewRewardApplyStep1,
    RewardApplyStep1,
    RewardApplyStep2,
    RewardApplyStep3,
    RewardApplyStep4,
    RewardApplyStep5,
    RewardApplyStep6,
  },
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      store.dispatch("setDocumentTitle", "프로젝트 신청");

      const projectSeq = router.app.$route.params.projectSeq;

      state.steps = [
        {title: "Wait a moment", desc: "Please wait a moment", ico: "page.apply.reward.step1.svg"},
        {title: "Wait a moment", desc: "Please wait a moment", ico: "page.apply.reward.step2.svg"}
      ];

      state.loaded = false;
      http.get(`/api/reward/projects/${projectSeq}/apply`).then(({data}) => {
        const set = (form) => {
          state.loaded = true;
          state.form.new = form;

          if (!["MEMBER_MID_ADMIN", "MEMBER_TOP_ADMIN"].includes(store.state.account.memberAuth)
              && !["STATUS_0001", "STATUS_0002", "STATUS_0003", "STATUS_0004"].includes(state.form.new.regState)) {
            state.escapable = true;
            store.commit("setSwingMessage", "이미 오픈된 프로젝트입니다. 마이페이지로 이동합니다.");
            return router.replace({path: "/mypage/open/reward"});
          }

          state.form.new.videoFlag = state.form.new.videoFlag || "N";
          state.form.new.crowdYn = state.form.new.crowdYn || "N";
          state.form.new.historyProjectFlag = state.form.new.historyProjectFlag || "N";
          state.form.new.partnershipFlag = state.form.new.partnershipFlag || "N";
          state.form.new.newEditorFlag = state.form.new.newEditorFlag || "N";

          if (state.form.new.simulationFlag === "Y") {
            if (!state.form.new.contestSeq) {
              store.commit("setSwingMessage", "파트너십 값이 없습니다. 관리자에게 문의해주세요.");
              return router.replace({path: "/apply/rewardIntro?mock"});
            }

            state.steps = [
              {step: 1, title: "기본 정보", desc: "기본 정보를 입력해 주세요.", ico: "page.apply.reward.step1.svg"},
              {step: 3, title: "스토리 작성", desc: "프로젝트 스토리를 작성해 주세요.", ico: "page.apply.reward.step3.svg"},
              {step: 5, title: "진행자 정보", desc: "진행자 정보를 입력해주세요.", ico: "page.apply.reward.step1.svg"},
            ];

            state.form.new.groupCate = state.form.new.groupCate || null;
            state.form.new.corpSector = state.form.new.corpSector || null;
            state.form.new.corpEstablishedFlag = state.form.new.corpEstablishedFlag || "Y";
            state.form.new.corpRegions = state.form.new.corpRegions || null;
            state.form.new.userSex = state.form.new.userSex || null;
            state.form.new.newEditorFlag = "N";
            state.form.new.adultFlag = "N";
            state.form.new.fundingType = "K";
            state.form.new.skipAddressFlag = "N";
            state.form.new.historyProjectFlag = "N";
          } else {
            state.steps = [
              {step: 1, title: "기본 정보", desc: "기본 정보를 입력해 주세요.", ico: "page.apply.reward.step2.svg"},
              {step: 2, title: "리워드 구성", desc: "리워드를 구성해 주세요.\n", ico: "page.apply.reward.step3.svg"},
              {step: 3, title: "스토리 작성", desc: "프로젝트 스토리를 작성해 주세요.", ico: "page.apply.reward.step3.svg"},
              {step: 4, title: "추가 정보", desc: "진행자님의 프로젝트에 맞춰 추가 정보를 입력해 주세요.", ico: "page.apply.reward.step2.svg"},
              {step: 5, title: "진행자 정보", desc: "진행자 정보를 입력해주세요.", ico: "page.apply.reward.step1.svg"},
              {step: 6, title: "정산", desc: "정산을 위한 서류를 첨부해주세요.", ico: "page.apply.reward.step4.svg"},
            ];
          }

          setFilesDefault();
          setFiles(state.form.new.projectFileItems);
          setBase();
          setStep();

          state.form.old = lib.getRenewed(state.form.new);
          state.files.old = lib.getRenewed(state.files.new);
        };

        const tempApplies = store.getters.tempApplies();
        const tempApply = tempApplies.find(a => a.projectSeq === projectSeq);

        if (tempApply) {
          store.commit("confirm", {
            message: "웹 브라우저에 저장된 임시 데이터가 있습니다.\n해당 데이터를 불러오시겠어요?",
            allow() {
              set(tempApply);
              store.commit("delTempApply", projectSeq);
            },
            disallow() {
              set(data.body);
              store.commit("delTempApply", projectSeq);
            }
          });
        } else {
          set(data.body);
        }
      }).catch(httpError((err) => {
        state.loaded = true;
        state.escapable = true;

        switch (err?.response?.status) {
          case 401:
            store.commit("setSwingMessage", definitions.messages.needLogin);
            return router.replace({path: "/apply/intro"});

          case 403:
            store.commit("setSwingMessage", "해당 프로젝트에 대한 진행 권한이 없습니다.");
            return router.replace({path: "/apply/intro", query: {projectSeq: router.app.$route.query.projectSeq}});

          default:
            return router.replace({path: "/apply/intro"});
        }
      }));
    });

    const state = reactive({
      step: 1,
      tab: 0,
      loaded: false,
      finish: false,
      escapable: false,
      openedSteps: [],
      validators: {},
      keys: {
        editor: 0,
      },
      trigger: false,
      files: {
        changed: false,
        old: {},
        new: {
          mainImages: [],
          bizRegFile: null,
          bankbook: null,
          certFile: null,
          etcDocFile: null,
          productFiles: [],
          socialFiles: [],
        },
        deletes: []
      },
      form: {
        old: {},
        new: {
          projectSeq: "",
          openId: "",
          introduction: "",
          userMail: "",
          userHp: "",
          userPost: "",
          userAddr1: "",
          userAddr2: "",
          homeUrl: "",
          facebook: "",
          blog: "",
          insta: "",
          projectName: "",
          projectCate: "",
          fundingType: "",
          paymethod: "",
          investStartDate: "",
          investEndDate: "",
          openReservationTime: "",
          targetAmt: 0,
          simpleText: "",
          videoFlag: "N",
          videoUrl: "",
          tag: "",
          partnershipFlag: "N",
          groupCate: "",
          corpSector: "",
          corpEstablished: "",
          corpEstablishedFlag: "",
          corpRegion: "",
          userSex: "",
          regState: "",
          tempStep: "",
          openFlag: "",
          rewardNoJoinFlag: "",
          rewardAddJoinFlag: "",
          projectInfo: "",
          crowdYn: "N",
          createDate: "",
          createId: "",
          updateDate: "",
          updateId: "",
          step1: "",
          step2: "",
          step3: "",
          step4: "",
          step5: "",
          requestDate: "",
          calculateAmt: "",
          calculateStatusCode: "",
          deliveryAmt: "",
          memberName: "",
          corporateName: "",
          contestSeq: "",
          fundingContestSeq: "",
          simulationFlag: "",
          historyProjectFlag: "N",
          historyUrl: "",
          memberFilePath: "",
          memberFileName: "",
          rewardItems: [],
          addInfoItems: [],
          projectFileItems: [],
          uploadFileItems: "",
          crowdList: [],
          adultFlag: "N",
          productNotice: "",
          ageGroups: "",
          managerName: "",
          managerPhone: "",
          managerEmail: "",
          newEditorFlag: "N",
          skipAddressFlag: "N",
        },
        default: {
          crowdListItem: {
            crowdsourcingType: "",
            title: "",
            content: "",
            delFlag: "N"
          },
          rewardItem: {
            cnt: "",
            createId: "",
            delFlag: "",
            displayOrder: "1",
            extraCount: "",
            joinCount: "",
            rewardAmt: null,
            rewardExpectText: "",
            rewardOptionItems: [],
            rewardQty: null,
            rewardSelFlag: "N",
            rewardSelText: "",
            rewardSeq: null,
            title: "",
            updateId: "",
            rewardQtyLimit: false
          },
          rewardOptionItem: {
            rewardOptionText: "",
            rewardOptionQty: 0,
            displayOrder: "1",
            delFlag: "N"
          }
        }
      },
      steps: []
    });

    const tabs = rewardStoryTabs;

    const wrapRef = ref();

    const computedIsAdminPage = computed(() => {
      return window.top !== window;
    });

    const computedSelectedStep = computed(() => {
      return state.steps.find(s => s.step === state.step) || {};
    });

    const computedQueryStep = computed(() => {
      return window.Number(router.app.$route.query.step) || 1;
    });

    const computedGetTabs = computed(() => {
      switch (state.step) {
        case 3: {
          if (state.form.new.newEditorFlag === "Y") {
            return tabs.step3;
          }

          const newTabs = tabs.step3.filter(t => t.name === "fundingInfo" || t.name === "fundingIntroTemplate");

          newTabs.push({
            title: "프로젝트 소개",
            name: "oldFundingIntro",
            ord: 2,
          });

          return newTabs;
        }

        default:
          return [];
      }
    });

    const moveScrollByTab = (idx) => {
      if (!computedGetTabs && !computedGetTabs?.length) {
        return;
      }

      const sectionTop = wrapRef.value.querySelectorAll(`.step${state.step} > .form-group`)[idx]?.getBoundingClientRect().top;
      const fixedTabHeight = document.getElementById(`${component.name}Tabs`)?.clientHeight || 0;
      const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      window.scrollTo({
        top: sectionTop + curScrollTop - fixedTabHeight - 30,
        left: 0,
        behavior: "smooth"
      });
    };

    const isChanged = () => {
      for (let i in state.files.new) {
        if (!state.files.new[i] && !state.files.old[i]) {
          continue;
        }

        if (Array.isArray(state.files.new[i])) {
          if (state.files.new[i]?.length !== state.files.old[i]?.length) {
            return true;
          }

          for (let j in state.files.new[i]) {
            if (state.files.new[i][j]?.lastModified !== state.files.old[i][j]?.lastModified) {
              return true;
            } else if (state.files.new[i][j]?.fileSaveName !== state.files.old[i][j]?.fileSaveName) {
              return true;
            }
          }
        } else if (state.files.new[i]?.lastModified !== state.files.old[i]?.lastModified) {
          return true;
        } else if (state.files.new[i]?.fileSaveName !== state.files.old[i]?.fileSaveName) {
          return true;
        }
      }

      for (let i in state.form.new) {
        if (!state.form.new[i] && !state.form.old[i]) {
          continue;
        }

        if (["string", "number"].includes(typeof state.form.new[i]) && state.form.new[i] !== state.form.old[i]) {
          return true;
        } else if (JSON.stringify(state.form.new[i]) !== JSON.stringify(state.form.old[i])) {
          return true;
        }
      }
    };

    const changeStep = (step, compare) => {
      if (!state.loaded) {
        return;
      } else if (step === state.step) {
        return;
      }

      if (compare) {
        if (!isChanged()) {
          pushRouterQuery(step);
        } else {
          store.commit("confirm", {
            message: "변경사항이 있습니다. 저장하시겠어요?",
            allow() {
              save({
                callback() {
                  pushRouterQuery(step);
                }
              });
            },
            disallow() {
              state.form.new = lib.getRenewed(state.form.old);
              state.files.new = lib.getRenewed(state.files.old);
              pushRouterQuery(step);
            }
          });
        }
      } else {
        pushRouterQuery(step);
      }
    };

    const warn = (message, id) => {
      store.commit("setSwingMessage", message);

      id && nextTick(() => {
        document.getElementById(id).focus();
      });

      return false;
    };

    const getStep = (num) => {
      const idx = state.steps.findIndex(s => s.step === state.step);
      return state.steps[idx + num]?.step;
    };

    const preview = () => {
      const pop = () => {
        window.open(`/reward/preview/${state.form.new.projectSeq}`, "");
      };

      if (isChanged()) {
        return store.commit("confirm", {
          message: "변경사항이 있습니다. 저장 후 미리보기 창을 여시겠어요?",
          allow() {
            save({
              callback: pop
            });
          },
          disallow: pop
        });
      }

      pop();
    };

    const pushRouterQuery = (step) => {
      computedQueryStep.value !== step && router.push({
        query: {step}
      });

      window.scrollTo(0, 0);
      setStep(step);
    };

    const pushStep = (step) => {
      !state.openedSteps.indexOf(step) >= 0 && state.openedSteps.push(step);
    };

    const setStep = () => {
      state.step = computedQueryStep.value;

      for (let i = 1; i <= state.step; i += 1) {
        pushStep(i);
      }
    };

    const setFiles = (files) => {
      for (let file of files) {
        switch (file.fileCode) {
          case "main_image":
            state.files.new.mainImages.push({
              ...file,
              main: true
            });
            break;

          case "sub_image":
            state.files.new.mainImages.push(file);
            break;

          case "biz_doc":
            state.files.new.bizRegFile = file;
            break;

          case "bankbook":
            state.files.new.bankbook = file;
            break;

          case "cert_doc":
            state.files.new.certFile = file;
            break;

          case "etc_doc":
            state.files.new.etcDocFile = file;
            break;

          case "product":
            state.files.new.productFiles.push(file);
            break;

          case "social":
            state.files.new.socialFiles.push(file);
            break;
        }
      }

      state.files.new.mainImages && (state.files.new.mainImages = state.files.new.mainImages.sort((a, b) => {
        if (a.main && !b.main) {
          return -1;
        } else if (!a.main && b.main) {
          return 1;
        } else {
          return 0;
        }
      }));
    };

    const setBase = () => {
      if (state.form.new.projectInfo === null) {
        state.form.new.projectInfo = ``;
      }

      if (state.form.new.rewardItems.length) {
        for (let i in state.form.new.rewardItems) {
          const rewardQtyLimit = !!Number(state.form.new.rewardItems[i].rewardQty);
          set(state.form.new.rewardItems[i], "rewardQtyLimit", rewardQtyLimit);

          if (!rewardQtyLimit) {
            state.form.new.rewardItems[i].rewardQty = null;
          }
        }
      } else {
        state.form.new.rewardItems.push(lib.getRenewed(state.form.default.rewardItem));
      }
    };

    const setArrayFiles = () => {
      for (let i in state.files.new) {
        if (Array.isArray(state.files.new[i])) {
          state.files.new[i] = state.files.new[i].filter((f) => {
            return f.fileCode;
          });
        }
      }
    };

    const setFilesDefault = () => {
      for (let i in state.files.new) {
        state.files.new[i] = Array.isArray(state.files.new[i]) ? [] : null;
      }
    };

    const saveAndMoveNext = () => {
      save({
        validate: true,
        callback() {
          pushRouterQuery(getStep(1));
        }
      });
    };

    const submit = () => {
      for (let i in state.steps) {
        const step = state.steps[i].step;
        pushRouterQuery(step);
        pushStep(step);

        if (typeof state.validators[step] === "function" && !state.validators[step]()) {
          return;
        }
      }

      store.commit("confirm", {
        message: "프로젝트를 최종 신청하시겠습니까?",
        allow() {
          save({
            finish: true,
          });
        }
      });
    };

    const getActivesFiles = () => {
      const files = [];

      for (let i1 in state.files.new) {
        if (Array.isArray(state.files.new[i1])) {
          for (let i2 in state.files.new[i1]) {
            if (i1 !== "mainImages") {
              files.push({key: i1, file: state.files.new[i1][i2]});
              continue;
            }

            if (state.files.new[i1][i2].main) {
              files.push({
                key: "mainImgFile",
                file: state.files.new[i1][i2],
              });
              continue;
            }

            files.push({
              key: "subImgFiles",
              file: state.files.new[i1][i2],
            });
          }
        } else if (state.files.new[i1]) {
          files.push({key: i1, file: state.files.new[i1]});
        }
      }

      return files;
    };

    const save = ({validate, finish, callback} = {}) => {
      store.commit("checkAccount", async () => {
        if (!store.state.account.loggedIn) {
          store.commit("saveTempApply", state.form.new);
          store.commit("setStickyMessage", {
            message: "입력하신 데이터는 웹 브라우저에 임시 저장하였습니다. 다시 로그인하신 후 진행해주시기 바랍니다.",
            type: "danger"
          });
          return;
        }

        if (validate && typeof state.validators[state.step] === "function" && !await state.validators[state.step]()) {
          return;
        }

        const args = lib.getRenewed(state.form.new);

        if (finish) {
          state.finish = true;
        } else {
          args[`step${state.step}`] = "1";
        }

        // request1
        const activesFiles = getActivesFiles().filter(f => !f.file.fileCode);

        if (activesFiles.length) {
          const formData = new FormData();
          formData.append("projectSeq", args.projectSeq);
          activesFiles.forEach(f => formData.append(f.key, f.file));

          const res1 = await http.post(`/api/reward/projects/${args.projectSeq}/uploads`, formData).catch(httpError());

          if (res1?.error) {
            return;
          }

          setArrayFiles();
          setFiles(res1.data.body);
        }

        args.projectFileItems = [];

        state.files.deletes.forEach(f => {
          f.delFlag = "Y";
          args.projectFileItems.push(f);
        });

        state.files.deletes = [];

        getActivesFiles().forEach(f => {
          if (f.key === "mainImgFile") {
            args.projectFileItems.push({...f.file, fileCode: "main_image"});
          } else if (f.key === "subImgFiles") {
            args.projectFileItems.push({...f.file, fileCode: "sub_image"});
          } else {
            args.projectFileItems.push(f.file);
          }
        });

        if (args.investStartDate) {
          args.investStartDate = args.investStartDate.replaceAll("-", "");
        }

        if (args.investEndDate) {
          args.investEndDate = args.investEndDate.replaceAll("-", "");
        }

        if (args.introduction) {
          args.introduction = args.introduction.trim();
        }

        if (args.projectName) {
          args.projectName = args.projectName.trim();
        }

        if (state.finish) {
          args.tempStep = "submit";
        } else if (computedIsAdminPage.value) {
          args.tempStep = "";
        } else {
          args.tempStep = "save";
        }

        // request2
        const res2 = await http.put(`/api/reward/projects/${args.projectSeq}/apply`, args).catch(httpError());

        if (res2?.error) {
          return;
        }

        //step3 story 저장 로직 trigger
        state.step === 3 && (state.trigger = true);

        if (state.finish) {
          window.onbeforeunload = null;
          store.commit("setSwingMessage", "프로젝트 신청을 완료했습니다.");
          return router.push({path: `/apply/rewardApplyDone${args.simulationFlag === "Y" ? "?mock" : ""}`});
        }

        state.form.new = res2.data.body;
        setBase();

        state.form.old = lib.getRenewed(state.form.new);
        state.files.old = lib.getRenewed(state.files.new);
        state.keys.editor += 1;

        setFilesDefault();
        setFiles(state.form.new.projectFileItems);

        store.commit("setSwingMessage", `${computedIsAdminPage.value ? "변경 사항을 저장하였습니다." : "임시 저장을 완료했습니다."}`);

        typeof callback === "function" && callback();
      });
    };

    const openExplanation = () => {
      store.commit("openModal", {
        name: "RewardApplyExamples",
        params: {
          invisibleExample: true,
          sectionName: "fundingIntroTemplate",
          sectionTitle: "프로젝트 소개 템플릿",
        }
      });
    };

    const onScroll = () => {
      const section = wrapRef.value.querySelectorAll(`.step${state.step} > .form-group`);
      const fixedTabHeight = document.getElementById(`${component.name}Tabs`)?.clientHeight || 0;
      const scrollY = window.pageYOffset;

      for (let i in section) {
        if (section[i] && typeof section[i].getBoundingClientRect === "function") {
          const rect = section[i].getBoundingClientRect();
          const start = rect.top + scrollY - fixedTabHeight - 31;
          const end = start + rect.height;

          if (scrollY > start && scrollY < end) {
            state.tab = Number(i);
            break;
          }
        }
      }

    };

    watch(() => router.app.$route.query.step, (next) => {
      changeStep(window.Number(next || 1));
    });

    onMounted(() => {
      store.commit("addComponentClass", {to: "header", value: "no-fixed"});
      store.commit("addComponentClass", {to: "ground", value: "no-padding-top"});
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    onUnmounted(() => {
      store.commit("removeComponentClass", {to: "header", value: "no-fixed"});
      store.commit("removeComponentClass", {to: "ground", value: "no-padding-top"});
      window.onbeforeunload = null;
    });

    return {
      component,
      state,
      computedSelectedStep,
      computedGetTabs,
      computedIsAdminPage,
      wrapRef,
      warn,
      getStep,
      preview,
      submit,
      save,
      saveAndMoveNext,
      changeStep,
      isChanged,
      moveScrollByTab,
      openExplanation,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.state.finish || this.state.escapable || !this.$store.state.account.checked || !this.$store.state.account.loggedIn || !this.isChanged() || (this.state.form.new.simulationFlag === "Y" && !this.state.form.new.contestSeq)) {
      next();
    } else {
      store.commit("confirm", {
        message: definitions.messages.beforeProjectApplyStop,
        allow: next
      });
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.reward-apply {
  background: $colorBackground;
  min-height: 700px;

  .tabs {
    background-color: $colorBackground;
    border-bottom: $px1 solid #eee;
    font-size: $px14;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    padding: $px16 0;
    overflow: auto;

    > .container {
      > .wrapper {
        display: flex;
        gap: $px24;
        padding-left: $px170;

        .tab {
          cursor: pointer;
          transition: 0.18s;
          color: $colorSecondary;
          font-weight: 500;
          white-space: nowrap;

          &.active {
            color: $colorPoint;
          }
        }
      }
    }
  }

  > .container {
    padding-top: $px16;
    padding-bottom: $px16;

    .title {
      margin-bottom: $px9;
    }

    .content {
      position: relative;
      padding-left: $px170;

      .steps {
        width: $px170;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: $px35;

        ul {
          position: relative;
          height: 100%;

          li {
            > a {
              display: block;
              margin: 0 0 $px50 0;
              position: relative;
              padding-left: $px52;
              text-decoration: none;

              .step {
                margin-bottom: $px2;
                display: inline-block;
              }

              .ico {
                position: absolute;
                top: $px5;
                left: 0;
                width: $px40;
                height: $px40;
                border-radius: 50%;
                border-width: $px1;
                border-style: solid;
                border-color: #eee;
                background: $colorBackground;

                .img {
                  width: $px30;
                  height: $px30;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }

              &.active {
                .title {
                  font-weight: 500;
                }
              }

              .completed {
                .img {
                  border-color: $colorPoint;
                }
              }

              &:not(.active):not(.completed) {
                .step, .title {
                  opacity: 0.75;

                  span {
                    white-space: pre-line;
                  }
                }

                &:hover {
                  .step, .title {
                    opacity: 1;
                  }
                }
              }

              .title > span {
                letter-spacing: $px-1;
              }
            }
          }
        }
      }

      .core {
        .header {
          padding-bottom: $px16;
          display: flex;
          gap: $px16;
          align-items: center;
          width: 100%;

          .desc {
            color: #666;
          }

          .select {
            display: flex;
            align-items: center;
            gap: $px8;

            .wrapper {
              border: $px1 solid $colorSecondary;
              border-radius: $px16;
              overflow: hidden;

              .btn {
                font-size: $px14;
                border-radius: 0;
                padding: $px4 $px12;
                white-space: nowrap;

                &:not(:last-child) {
                  border-right: $px1 solid $colorSecondary;
                }

                &.selected, &:hover {
                  background: $colorSecondary;
                  color: #fff;
                }
              }
            }
          }
        }

        > .wrapper {
          background: #fff;
          padding: $px50;
          border: $px1 solid #eee;
        }

        .actions {
          position: sticky;
          bottom: 0;
          left: 0;
          background: #fff;
          z-index: 1201;
          margin: 0 -15px $px-20;
          padding: $px20 15px;
          display: flex;
          gap: $px15;

          > div {
            flex: 1;

            .btn {
              white-space: nowrap;
              padding: $px18;
            }

            &.main {
              flex-basis: 30%;
            }
          }
        }
      }
    }
  }

  &.skeleton {
    .tabs > .container > .wrapper .tab {
      @include skeleton;
    }

    > .container {
      .content {
        .steps ul li {
          .step {
            @include skeleton;
          }

          .title > span {
            @include skeleton;
          }

          .ico {
            @include skeleton;

            .img {
              display: none;
            }
          }
        }
      }

      .core {
        > .wrapper .header {
          .title {
            @include skeleton;
          }

          .desc > span {
            @include skeleton;
          }
        }

        .actions .btn {
          @include skeleton;
        }
      }
    }
  }

  @media(max-width: 991px) {
    .tabs > .container > .wrapper {
      padding: 0;

      .tab {
        &:last-child {
          padding-right: $px16;
        }
      }
    }
    > .container {
      background: #fff;

      .content {
        padding: 0;

        .steps {
          display: none;
        }

        .core {
          > .header {
            flex-direction: column;
            align-items: flex-start;

            .select {
              width: 100%;

              > .wrapper {
                flex-grow: 1;

                > .btn {
                  width: 50%;
                }
              }
            }
          }

          > .wrapper {
            padding: 0;
            border: 0;

            .actions {
              flex-wrap: wrap;

              > div {
                flex: initial;

                &.second {
                  width: calc((100% - $px32) / 3);
                }

                &.main {
                  width: 100%;
                }
              }

              &.step1 {
                > div {
                  &.second {
                    width: calc((100% - $px16) / 2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .container .content .core > .wrapper .header {
      .desc {
        padding-right: 0;
      }

      .guides {
        position: static;
        padding-top: $px18;
      }
    }
  }
}
</style>